import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import ComplexTranslate from 'components/ComplexTranslate';
import { CountDown } from 'components/common/CountDown/CountDown';
import { CONFIRMATION_HANDLE_TYPES, TWO_FACTOR_AUTH_TYPES } from 'components/common/constants';
import { getConfirmationActionType } from 'services/authUtils';
import Alert from 'uikit/Alert/Alert';
import { SecurityInput } from 'uikit/SecurityInput/SecurityInput';
import './ConfirmCodeWrapper.scss';

const RESEND_TIMEOUT = 60;
const CODE_SENT_TIMEOUT = 'CODE_SEND_TIMEOUT';

export const ConfirmCodeWrapper = ({
  type = getConfirmationActionType() === TWO_FACTOR_AUTH_TYPES.GOOGLE_AUTH ? 'google' : 'sms',
  PhoneComponent,
  isDisabled,
  error,
  onComplete,
  showCountDown,
  handleResendCode,
  onTimerEnd,
  alertState,
  isAuth = false,
  isMessenger = false,
  isCardCreationForm = false,
  isGoogle2FASignUpPopUp = false,
  handleSwitchToSmsAuth,
  confirmationHandleType = CONFIRMATION_HANDLE_TYPES.ON_COMPLETE
}) => {
  const i18n = useContext(i18nContext);

  return (
    <div
      className={
        isMessenger
          ? 'sms-code-wrapper-messenger'
          : isCardCreationForm
          ? 'sms-code-wrapper-card-creation-form'
          : 'sms-code-wrapper'
      }
    >
      <Alert type={alertState.type} message={i18n.getMessage(alertState.message)} />
      <div className={isMessenger ? 'sms-description-messenger' : 'sms-description'}>
        <ComplexTranslate
          text={
            type === 'sms'
              ? 'sms.description'
              : isGoogle2FASignUpPopUp
              ? '2fa.signup.description'
              : '2fa.auth.description'
          }
          interpolation={{
            phone: PhoneComponent
          }}
        />
      </div>
      <SecurityInput
        disabled={isDisabled}
        type={'sms'}
        error={alertState.type}
        onComplete={onComplete}
        confirmationHandleType={confirmationHandleType}
      />
      {(isAuth || (!isAuth && type === 'sms')) && (
        <div className='resend-wrapper'>
          {!showCountDown ? (
            <ComplexTranslate
              text={'sms.description.resend'}
              interpolation={{
                resendCode:
                  type === 'sms' ? (
                    <button
                      className='unset link-normal'
                      disabled={error?.code === CODE_SENT_TIMEOUT}
                      onClick={(e) => handleResendCode(e)}
                    >
                      {i18n.getMessage('sms.resendCode')}
                    </button>
                  ) : (
                    <>
                      <span> {i18n.getMessage('sms.otherMethods')}</span>
                      <button className='unset link-normal' onClick={handleSwitchToSmsAuth}>
                        {i18n.getMessage('sms.switchToSms')}
                      </button>
                    </>
                  )
              }}
            />
          ) : (
            <p className='resend-info'>
              {i18n.getMessage('sms.resentMessage') + ' '}
              <CountDown onEnd={onTimerEnd} duration={RESEND_TIMEOUT} />
            </p>
          )}
        </div>
      )}
    </div>
  );
};

ConfirmCodeWrapper.propTypes = {
  type: PropTypes.oneOf(['sms', 'google']),
  PhoneComponent: PropTypes.object,
  isDisabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onComplete: PropTypes.func,
  showCountDown: PropTypes.bool,
  handleResendCode: PropTypes.func,
  onTimerEnd: PropTypes.func,
  isAuth: PropTypes.bool,
  isMessenger: PropTypes.bool,
  isCardCreationForm: PropTypes.bool,
  isGoogle2FASignUpPopUp: PropTypes.bool,
  handleSwitchToSmsAuth: PropTypes.func,
  confirmationHandleType: PropTypes.string
};
