import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import CountdownTimer from './CountdownTimer';
import { ReactComponent as CurrencyExchangeSwitchIcon } from 'assets/switch-icon.svg';
import AmountInput from 'components/common/AmountInput';
import { CURRENCY_SYMBOLS, EXCHANGE_AMOUNT_DIRECTION } from 'components/common/constants';
import Loader from 'components/common/Loader';
import { filterPaymentAccounts } from 'components/common/PaymentForm/utils';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import i18nContext from 'components/i18n-context';
import { getAccountTitle, hasMultipleCurrencies } from 'components/utils';
import { amountFormattedValue, getErrorMessageForAlert, findAccountByCurrency } from 'services/utils';
import { ROUTE_PATHS } from 'routes/constants';
import Alert from 'uikit/Alert/Alert';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { IconButton } from 'uikit/IconButton/IconButton';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './CurrencyExchange.scss';

const EXCHANGE_RATE_PRECISION = 4;
const ACCOUNT_TYPE = {
  FROM: 'accountFrom',
  TO: 'accountTo'
};

const CurrencyExchange = ({ userAccounts, customerNumber, currencyExchangeStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currencyExchangeStore.isRepeatTransaction) {
      currencyExchangeStore.resetExchangeStore();
    } else {
      currencyExchangeStore.createExchangeQuote(customerNumber).then(() => {
        if (currencyExchangeStore.amountFrom) {
          currencyExchangeStore
            .calculateExchangeData(
              customerNumber,
              currencyExchangeStore.amountFrom,
              EXCHANGE_AMOUNT_DIRECTION.AMOUNT_FROM
            )
            .then(() => {
              currencyExchangeStore.setIsRepeatTransactionStatus(false);
            });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currencyExchangeStore.isRepeatTransaction) {
      const paymentAccounts = filterPaymentAccounts(userAccounts);

      if (hasMultipleCurrencies(paymentAccounts)) {
        const accountFrom = findAccountByCurrency({ accounts: paymentAccounts });
        const accountTo = findAccountByCurrency({
          accounts: paymentAccounts,
          currencyForExclude: accountFrom?.currency
        });
        currencyExchangeStore.setFromToAccounts(accountFrom, accountTo);
        if (currencyExchangeStore.accountFrom && currencyExchangeStore.accountTo) {
          currencyExchangeStore.createExchangeQuote(customerNumber);
        }
      } else {
        navigate(ROUTE_PATHS.DASHBOARD);
      }
    }
  }, [userAccounts]);

  useEffect(() => {
    if (currencyExchangeStore.isExchangeSucceeded) {
      userStore.getUserAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyExchangeStore.isExchangeSucceeded]);

  const handleChangeAccount = async (name, value) => {
    const oppositeAccountType = ACCOUNT_TYPE.FROM === name ? ACCOUNT_TYPE.TO : ACCOUNT_TYPE.FROM;
    const paymentAccounts = filterPaymentAccounts(userAccounts);
    const selectedAccount = paymentAccounts.find((account) => account.wallet_number === value);
    const oppositeAccount =
      currencyExchangeStore?.[oppositeAccountType]?.currency === selectedAccount.currency
        ? findAccountByCurrency({ accounts: paymentAccounts, currencyForExclude: selectedAccount.currency })
        : currencyExchangeStore?.[oppositeAccountType];
    if (selectedAccount?.wallet_number !== currencyExchangeStore?.[name]?.wallet_number) {
      if (ACCOUNT_TYPE.FROM === name) {
        currencyExchangeStore.setFromToAccounts(selectedAccount, oppositeAccount);
      } else {
        currencyExchangeStore.setFromToAccounts(oppositeAccount, selectedAccount);
      }
      if (currencyExchangeStore.accountFrom && currencyExchangeStore.accountTo) {
        await currencyExchangeStore.createExchangeQuote(customerNumber);
        if (currencyExchangeStore.amountFrom) {
          await currencyExchangeStore.calculateExchangeData(
            customerNumber,
            currencyExchangeStore.amountFrom,
            EXCHANGE_AMOUNT_DIRECTION.AMOUNT_FROM
          );
        }
      }
    }
  };

  const handleAmountChange = ({ target: { name, value } }) => {
    currencyExchangeStore.setAmount(name, value);
  };

  const handleCurrencyExchangeSwitch = async () => {
    currencyExchangeStore.setFromToAccounts(currencyExchangeStore.accountTo, currencyExchangeStore.accountFrom);
    if (currencyExchangeStore.accountFrom && currencyExchangeStore.accountTo) {
      await currencyExchangeStore.createExchangeQuote(customerNumber);
      if (currencyExchangeStore.amountFrom) {
        await currencyExchangeStore.calculateExchangeData(
          customerNumber,
          currencyExchangeStore.amountFrom,
          EXCHANGE_AMOUNT_DIRECTION.AMOUNT_FROM
        );
      }
    }
  };

  const getAvailableBalance = (account) =>
    account &&
    i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(account.available),
      currency: account.currency
    });

  const accountsOptions = filterPaymentAccounts(userAccounts)?.map((account) => {
    const title = getAccountTitle(account);

    return {
      key: account?.wallet_number,
      value: title,
      currency: account?.currency
    };
  });

  const getCommission = async (amount, direction) => {
    if (currencyExchangeStore.accountFrom && currencyExchangeStore.accountTo && amount && Number(amount) !== 0) {
      await currencyExchangeStore?.calculateExchangeData(customerNumber, amount, direction);
    } else {
      currencyExchangeStore.clearAmounts();
    }
  };

  const handleExchangeTransferCreate = async () => {
    await currencyExchangeStore.createExchangeTransfer(customerNumber);
  };

  const refreshQuote = async () => {
    if (currencyExchangeStore.accountFrom && currencyExchangeStore.accountTo) {
      await currencyExchangeStore.createExchangeQuote(customerNumber);
    }
  };

  const isAccountsExist = currencyExchangeStore.accountFrom && currencyExchangeStore.accountTo;

  return (
    <section className='right-section currency-exchange'>
      <Container header={i18n.getMessage('container.exchange')}>
        <div className='exchange-wrapper'>
          <Alert
            type={'warning'}
            className='exchange-wrapper-alert'
            message={
              !isAccountsExist
                ? i18n.getMessage('transfer.provider.notFound')
                : currencyExchangeStore?.error
                ? getErrorMessageForAlert(i18n, currencyExchangeStore.error)
                : ''
            }
          />
          <div className='exchange-form-wrapper'>
            <div className='exchange-inputs-block'>
              <InputDropDown
                isRequired={true}
                name={ACCOUNT_TYPE.FROM}
                topLabel={getAvailableBalance(currencyExchangeStore.accountFrom)}
                value={currencyExchangeStore.accountFrom?.wallet_number}
                currency={currencyExchangeStore.accountFrom?.currency}
                options={accountsOptions}
                onChange={handleChangeAccount}
                label={i18n.getMessage('currencyExchange.form.from')}
                isMulti={false}
                isDisabled={currencyExchangeStore.isExchangeLoading}
              />
              <AmountInput
                isRequired={true}
                label={i18n.getMessage('currencyExchange.form.sellAmount')}
                name='amountFrom'
                value={currencyExchangeStore?.amountFrom?.toString()}
                onChange={handleAmountChange}
                placeholder={i18n.getMessage('currencyExchange.form.amountPlaceholder')}
                onBlur={async (e) => await getCommission(e?.target?.value, EXCHANGE_AMOUNT_DIRECTION.AMOUNT_FROM)}
                isDisabled={currencyExchangeStore.fieldsAreDisabled || currencyExchangeStore.isExchangeLoading}
              />
            </div>
            <IconButton
              className={'switch-button'}
              Icon={CurrencyExchangeSwitchIcon}
              onClick={handleCurrencyExchangeSwitch}
              isDisabled={currencyExchangeStore.isExchangeLoading}
            />
            <div className='exchange-inputs-block'>
              <InputDropDown
                isRequired={true}
                name={ACCOUNT_TYPE.TO}
                topLabel={getAvailableBalance(currencyExchangeStore.accountTo)}
                value={currencyExchangeStore.accountTo?.wallet_number}
                currency={currencyExchangeStore.accountTo?.currency}
                options={accountsOptions}
                onChange={handleChangeAccount}
                label={i18n.getMessage('currencyExchange.form.to')}
                isMulti={false}
                isDisabled={currencyExchangeStore.isExchangeLoading}
              />
              <AmountInput
                isRequired={true}
                label={i18n.getMessage('currencyExchange.form.buyAmount')}
                name='amountTo'
                value={currencyExchangeStore?.amountTo?.toString()}
                placeholder={i18n.getMessage('currencyExchange.form.amountPlaceholder')}
                onChange={handleAmountChange}
                onBlur={async (e) => await getCommission(e?.target?.value, EXCHANGE_AMOUNT_DIRECTION.AMOUNT_TO)}
                isDisabled={currencyExchangeStore.fieldsAreDisabled || currencyExchangeStore.isExchangeLoading}
              />
            </div>
            <div className='exchange-rate-commission-wrapper'>
              <div className='exchange-rate-commission-block'>
                <span className='exchange-rate-commission-title'>
                  {i18n.getMessage('currencyExchange.form.yourRate')}
                </span>
                {currencyExchangeStore.exchangeData?.rate && (
                  <span className='exchange-rate-commission-value'>
                    {`1${CURRENCY_SYMBOLS[currencyExchangeStore.accountFrom?.currency]} = ${amountFormattedValue(
                      currencyExchangeStore.exchangeData?.rate,
                      EXCHANGE_RATE_PRECISION
                    )}${CURRENCY_SYMBOLS[currencyExchangeStore.accountTo?.currency]}`}
                  </span>
                )}
              </div>
              <div className='exchange-rate-commission-block'>
                <span className='exchange-rate-commission-title'>
                  {i18n.getMessage('currencyExchange.form.commission', {
                    sellCurrency: currencyExchangeStore.accountFrom?.currency,
                    buyCurrency: currencyExchangeStore.accountTo?.currency
                  })}
                </span>
                {currencyExchangeStore?.exchangeData?.commission !== null && (
                  <span className='exchange-rate-commission-value'>
                    {`${amountFormattedValue(currencyExchangeStore?.exchangeData?.commission)} ${
                      currencyExchangeStore.accountFrom?.currency
                    }`}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='exchange-rate-timer-wrapper'>
            <CountdownTimer
              duration={currencyExchangeStore.exchangeData.duration}
              quoteId={currencyExchangeStore.exchangeData.quoteId}
              onComplete={refreshQuote}
            />
          </div>
          {isAccountsExist && (
            <div className='exchange-button-wrapper'>
              <Button
                type={'primary'}
                roleType={'submit'}
                size={'large'}
                isDisabled={
                  currencyExchangeStore.fieldsAreDisabled ||
                  !!currencyExchangeStore?.error ||
                  currencyExchangeStore.isExchangeLoading ||
                  !currencyExchangeStore.isCalculatedFromToValueAndCommission
                }
                onClick={handleExchangeTransferCreate}
              >
                {currencyExchangeStore.isExchangeLoading ? (
                  <Loader />
                ) : (
                  i18n.getMessage('currencyExchange.form.exchangeNow')
                )}
              </Button>
            </div>
          )}
        </div>
      </Container>
      <PopUp
        className={currencyExchangeStore?.isExchangeSucceeded ? 'transaction-success' : 'transaction-info'}
        show={currencyExchangeStore?.isExchangeTransferCreated || currencyExchangeStore?.isExchangeSucceeded}
        alignOnCenter={currencyExchangeStore?.isExchangeSucceeded}
        onClose={
          currencyExchangeStore?.isExchangeTransferCreated
            ? () => currencyExchangeStore?.resetCreatedExchangeTransfer()
            : () => currencyExchangeStore?.resetAfterSuccessfulExchange()
        }
      >
        {currencyExchangeStore?.isExchangeSucceeded ? (
          <PopUpSuccessScheme
            onClose={() => currencyExchangeStore.resetAfterSuccessfulExchange()}
            message={i18n.getMessage('popUp.message.moneyTransfer')}
          />
        ) : (
          <TransferConfirmationScheme
            isCurrency={true}
            transferData={currencyExchangeStore.createdExchangeTransferData}
            error={currencyExchangeStore?.confirmationPopupError}
            resendSecurityCode={() => currencyExchangeStore.resendExchangeSecurityCode()}
            clearError={() => currencyExchangeStore?.clearConfirmationPopupError()}
            onConfirm={(securityCode) => currencyExchangeStore?.confirmExchangeTransfer(customerNumber, securityCode)}
            onClose={() => currencyExchangeStore?.resetCreatedExchangeTransfer()}
          />
        )}
      </PopUp>
    </section>
  );
};

CurrencyExchange.propTypes = {
  userAccounts: PropTypes.array,
  customerNumber: PropTypes.string,
  currencyExchangeStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userAccounts: stores.userStore?.userAccounts,
  customerNumber: stores.userStore.userData.account?.account_number,
  currencyExchangeStore: stores.currencyExchangeStore,
  userStore: stores.userStore
}))(observer(CurrencyExchange));
